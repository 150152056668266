import React from 'react'
import styled from 'styled-components'
import { color, fontFamily } from 'src/assets/styles/variable'
import { ReactComponent as WarnIcon } from 'src/assets/images/warn.svg'

interface ConfirmProps {
  title?: string
  message: string
  onConfirm: () => Promise<void>
  onCancel: () => void
  confirmText?: string
  cancelText?: string
}

export const Confirm: React.FC<ConfirmProps> = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = 'OK',
  cancelText = 'キャンセル'
}) => {
  const handleConfirm = async () => {
    try {
      await onConfirm()
    } catch (error) {
      console.error('Confirm error:', error)
    }
  }

  return (
    <StyledConfirmContainer>
      <div className="confirm-content">
        <WarnIcon />
        {title && <h2 className="confirm-title">{title}</h2>}
        <p className="confirm-message">{message}</p>
        <div className="button-group">
          <button className="cancel-button" onClick={onCancel}>
            {cancelText}
          </button>
          <button className="confirm-button" onClick={handleConfirm}>
            {confirmText}
          </button>
        </div>
      </div>
    </StyledConfirmContainer>
  )
}

const StyledConfirmContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .confirm-content {
    background: white;
    padding: 46px 20px;
    border-radius: 8px;
    text-align: center;
    width: 90%;
    max-width: 400px;
  }

  .confirm-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    color: ${color.main};
  }

  .confirm-title {
    ${fontFamily.notoJp}
    font-size: 1.8rem;
    line-height: 150%;
    margin-bottom: 10px;
  }

  .confirm-message {
    ${fontFamily.notoJp}
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .button-group {
    display: flex;
    gap: 10px;
  }

  .cancel-button,
  .confirm-button {
    ${fontFamily.notoJp}
    flex: 1;
    font-weight: bold;
    font-size: 1.8rem;
    height: 48px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }

  .cancel-button {
    background: #f5f5f5;

    &:hover {
      background: #ebebeb;
    }
  }

  .confirm-button {
    background: linear-gradient(90deg, #86d0e2 0%, #6ec3d8 100%);
    color: white;

    &:hover {
      opacity: 0.9;
    }
  }
`
