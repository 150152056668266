import React, { useEffect } from 'react'
import styled from 'styled-components'
import { fontFamily } from 'src/assets/styles/variable'

interface SnackbarProps {
  open: boolean
  message: string
  severity?: 'success' | 'error' | 'warning' | 'info'
  autoHideDuration?: number
  onClose: () => void
}

export const Snackbar: React.FC<SnackbarProps> = ({
  open,
  message,
  severity = 'info',
  autoHideDuration = 5000,
  onClose
}) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose()
      }, autoHideDuration)

      return () => clearTimeout(timer)
    }
  }, [open, autoHideDuration, onClose])

  if (!open) return null

  return (
    <StyledSnackbarContainer>
      <div className={`snackbar-content ${severity}`}>
        <p className="snackbar-message">{message}</p>
      </div>
    </StyledSnackbarContainer>
  )
}

const StyledSnackbarContainer = styled.div`
  position: fixed;
  top: 24px;
  left: 20%;
  transform: translateX(-50%);
  z-index: 1400;

  .snackbar-content {
    min-width: 300px;
    max-width: 500px;
    padding: 44px 50px;
    border-radius: 4px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slideUp 0.3s ease-out;
  }

  .snackbar-message {
    ${fontFamily.notoJp}
    color: white;
    font-size: 1.4rem;
    margin: 0;
  }

  .success {
    background-color: #4caf50;
  }

  .error {
    background-color: #f44336;
  }

  .warning {
    background-color: #ff9800;
  }

  .info {
    background: linear-gradient(90deg, #86d0e2 0%, #6ec3d8 100%);
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`
