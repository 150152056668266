import { ServiceAccount } from 'firebase-admin'
import { EnvironmentalVariables } from 'src/lib/constants/values'

export const FIREBASE_SERVICE_ACCOUNTS: EnvironmentalVariables<ServiceAccount> =
  {
    local: {
      privateKey: process.env.FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY,
      projectId: 'smooth-prototype-development',
      clientEmail:
        'firebase-adminsdk-1f219@smooth-prototype-development.iam.gserviceaccount.com'
    },
    development: {
      privateKey: process.env.FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY,
      projectId: 'smooth-prototype-development',
      clientEmail:
        'firebase-adminsdk-1f219@smooth-prototype-development.iam.gserviceaccount.com'
    },
    staging: {
      privateKey: process.env.FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY,
      projectId: 'smooth-staging-eb1b7'
    },
    production: {
      privateKey: process.env.FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY,
      projectId: 'smooth-prod',
      clientEmail: 'firebase-adminsdk-4xb35@smooth-prod.iam.gserviceaccount.com'
    }
  }

export const GA_IDS: EnvironmentalVariables<string> = {
  local: 'G-ERZFXLM7M2',
  development: 'G-ERZFXLM7M2',
  staging: 'G-ERZFXLM7M2',
  production: 'G-WQW78PWJMD'
}
