import React, { createContext, useContext, useState, ReactNode } from 'react'
import { Alert } from 'src/components/Alert'
import { Confirm } from 'src/components/Confirm'
import { Snackbar } from 'src/components/Snackbar'

// 通知の種類を定義
type NotificationType = 'alert' | 'confirm' | 'snackbar'

// 通知の状態を定義
interface NotificationState {
  isOpen: boolean
  type: NotificationType
  message: string
  title?: string
  severity?: 'success' | 'error' | 'warning' | 'info'
  onConfirm?: () => Promise<void>
}

interface NotificationContextType {
  showAlert: (message: string, title?: string) => void
  showConfirm: (
    message: string,
    onConfirm: () => Promise<void>,
    title?: string
  ) => void
  showSnackbar: (
    message: string,
    severity?: 'success' | 'error' | 'warning' | 'info'
  ) => void
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
)

export const useNotification = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    )
  }
  return context
}

interface NotificationProviderProps {
  children: ReactNode
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children
}) => {
  const [notificationState, setNotificationState] = useState<NotificationState>(
    {
      isOpen: false,
      type: 'alert',
      message: '',
      title: undefined,
      severity: 'info'
    }
  )

  const showAlert = (message: string, title?: string) => {
    setNotificationState({
      isOpen: true,
      type: 'alert',
      message,
      title
    })
  }

  const showConfirm = (
    message: string,
    onConfirm: () => Promise<void>,
    title?: string
  ) => {
    setNotificationState({
      isOpen: true,
      type: 'confirm',
      message,
      title,
      onConfirm: onConfirm
    })
  }

  const showSnackbar = (
    message: string,
    severity: 'success' | 'error' | 'warning' | 'info' = 'info'
  ) => {
    setNotificationState({
      isOpen: true,
      type: 'snackbar',
      message,
      severity
    })
  }

  const handleConfirm = async () => {
    if (notificationState.onConfirm) {
      await notificationState.onConfirm()
    }
    setNotificationState((prev) => ({ ...prev, isOpen: false }))
  }

  return (
    <NotificationContext.Provider
      value={{ showAlert, showConfirm, showSnackbar }}
    >
      {children}
      {notificationState.isOpen && (
        <>
          {notificationState.type === 'confirm' && (
            <Confirm
              message={notificationState.message}
              title={notificationState.title}
              onConfirm={handleConfirm}
              onCancel={() =>
                setNotificationState((prev) => ({ ...prev, isOpen: false }))
              }
            />
          )}
          {notificationState.type === 'alert' && (
            <Alert
              message={notificationState.message}
              title={notificationState.title}
              onConfirm={() =>
                setNotificationState((prev) => ({ ...prev, isOpen: false }))
              }
            />
          )}
          {notificationState.type === 'snackbar' && (
            <Snackbar
              open={notificationState.isOpen}
              message={notificationState.message}
              severity={notificationState.severity}
              onClose={() =>
                setNotificationState((prev) => ({ ...prev, isOpen: false }))
              }
            />
          )}
        </>
      )}
    </NotificationContext.Provider>
  )
}
