import React from 'react'
import styled from 'styled-components'
import { color, fontFamily } from 'src/assets/styles/variable'
import { ReactComponent as WarnIcon } from 'src/assets/images/warn.svg'

interface AlertProps {
  title?: string
  message: string
  onConfirm: () => void
}

export const Alert: React.FC<AlertProps> = ({ title, message, onConfirm }) => {
  return (
    <StyledAlertContainer>
      <div className="alert-content">
        <WarnIcon />
        {title && <h2 className="alert-title">{title}</h2>}
        <p className="alert-message">{message}</p>
        <button className="confirm-button" onClick={onConfirm}>
          OK
        </button>
      </div>
    </StyledAlertContainer>
  )
}

const StyledAlertContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .alert-content {
    background: white;
    padding: 46px 20px;
    border-radius: 8px;
    text-align: center;
    width: 90%;
    max-width: 400px;
  }

  .alert-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    color: ${color.main};
  }

  .alert-title {
    ${fontFamily.notoJp}
    font-size: 1.8rem;
    line-height: 150%;
    margin-bottom: 10px;
  }

  .alert-message {
    ${fontFamily.notoJp}
    font-size: 1.6rem;
    margin-bottom: 20px;
  }

  .confirm-button {
    ${fontFamily.notoJp}
    font-weight: bold;
    font-size: 1.8rem;
    width: 100%;
    height: 48px;
    background: linear-gradient(90deg, #86d0e2 0%, #6ec3d8 100%);
    border: none;
    border-radius: 6px;
    color: white;
    cursor: pointer;
  }
`
