import { datadogRum } from '@datadog/browser-rum'
import { AppProps } from 'next/app'
import { NextRouter, useRouter } from 'next/router'
import Script from 'next/script'
import React from 'react'
import { Provider } from 'react-redux'
import { NotificationProvider } from 'src/contexts/NotificationContext'
import { GA_IDS } from 'src/features/admin/api/constants'
import { Logger } from 'src/features/log'
import { EnvName } from 'src/lib/constants/envName'
import { DATADOG_PARAMS } from 'src/lib/constants/values'
import { store } from 'src/store/store'

if (
  process.env.ENV_NAME === 'local' &&
  process.env.NEXT_PUBLIC_USE_API_MOCK === 'true'
) {
  const MockServer = () => require('../../mocks/worker')
  MockServer()
}

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  React.useEffect(() => {
    datadogRum.init(DATADOG_PARAMS)
  }, [])

  return (
    <Provider store={store}>
      <NotificationProvider>
        <Logger>
          <GlobalEventBinder router={router} />
          <Component {...pageProps} />
        </Logger>
      </NotificationProvider>
    </Provider>
  )
}

const GlobalEventBinder: React.VFC<{
  router: NextRouter
}> = ({ router }) => {
  const GA_ID = GA_IDS[process.env.ENV_NAME as EnvName]

  return (
    <>
      <Script
        id="ga_script_base"
        strategy="afterInteractive"
        src={'https://www.googletagmanager.com/gtag/js?id=' + GA_ID}
      />
      <Script
        id="ga_script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${GA_ID}')
gtag('config', 'AW-736246842')`
        }}
      />
    </>
  )
}

export default App
