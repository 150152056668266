import { Claims } from 'src/store/auth'

// ローカル環境用のclaims情報
export const LOCAL_USER_CLAIMS: Claims = {
  store_id: 2, // 差し替えたいStoreIdを指定する
  role: 'admin'
}

export const shouldUseMockClaims = (): boolean => {
  return process.env.ENABLE_MOCK_CLAIMS === 'true'
}
